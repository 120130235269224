
import { Component, Mixins } from 'vue-property-decorator';
import SidenaviSearchdate from './';
const Words: Dictionary = {
  label: '日付から探す',
  datelabel: '宿泊希望日',
  year: '年',
  month: '月',
  from: 'から',
  pleoplelabel: '人数',
  night: '泊',
  many: '名',
  room: '室',
  buttonlabel: '空室カレンダー(当エリア)',
  note: '※空室カレンダーは、三井ガーデンホテル・ザ セレスティンホテルズが対象です。最大3泊まで空室検索可能です。4泊以上の検索は各ホテルの「ご予約」ボタンをご利用ください。',
};
@Component
export default class SidenaviSearchdateJa extends Mixins(SidenaviSearchdate) {
  private words = Words;
}
