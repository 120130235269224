import { Component, Mixins, Prop, Emit } from 'vue-property-decorator';
import store from '@/store';
import ButtonToday from '@/mixins/ButtonToday';
@Component
export default class SidenaviSearchToday extends Mixins(ButtonToday) {
  private name = 'sidenavisearchtoday';
  @Prop({ default: 'tokyo' })
  private area!: string;
  @Emit()
  private clickButton(): void {
    window.gtag('event', 'CrntFunction', {event_category: 'todayHotel', event_label: 'todayHotel03'});
  }
}
