import { Component, Vue, Emit, Watch, Prop } from 'vue-property-decorator';
import store from '@/store';
import $ from 'jquery';
import magnificPopup from 'magnific-popup';
// @ is an alias to /src
@Component
export default class SidenaviSearchdate extends Vue {
  private name = 'sidenavisearchdate';
  private isActiveCal: boolean = false;
  // 希望日
  private nowD: Date = new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate());
  private reserveD: Date = new Date();
  private optionsMaxStay = 3;
  private optionsMaxPeople = 6;
  get dayOfWeekStr(): string[] {
    if (store.getters['auth/lang'] === 'ja') {
      return ['月', '火', '水', '木', '金', '土', '日'];
    } else {
      return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    }
  }
  private MonthEnglishList = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'June', 'July', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.'];
  get calLabel(): string {
    if (store.getters['auth/lang'] === 'ja') {
      return `${this.currentYear}年 ${this.currentMonth}月`;
    } else {
      return `${this.MonthEnglishList[this.currentMonth - 1]} ${this.currentYear}`;
    }
  }
  get nextCalLabel(): string {
    if (store.getters['auth/lang'] === 'ja') {
      return `${this.nextYear}年 ${this.nextMonth}月`;
    } else {
      return `${this.MonthEnglishList[this.nextMonth - 1]} ${this.nextYear}`;
    }
  }
  private currentCal!: Date[][]|null[][];
  private nextCal!: Date[][]|null[][];
  get valD(): Date {
    return this.$store.getters['searchdate/valueD'];
  }
  get maxDate(): Date {
    return this.$store.getters['searchdate/maxDate'];
  }
  get valueYear(): number {
    return this.valD.getFullYear();
  }
  get valueMonth(): number {
    return this.valD.getMonth() + 1;
  }
  get valueDate(): number {
    return this.valD.getDate();
  }
  get currentD(): Date {
    return this.$store.getters['searchdate/currentD'];
  }
  get valueStay(): number {
    return this.$store.getters['searchdate/valueStay'];
  }
  set valueStay(value: number) {
    this.$store.commit('searchdate/setValueStay', value);
  }
  get valuePeople(): number {
    return this.$store.getters['searchdate/valuePeople'];
  }
  set valuePeople(value: number) {
    this.$store.commit('searchdate/setValuePeople', value);
  }
  @Prop({default: null})
  private myClass!: string|null;
  private created() {
    this.setVal(this.nowD);
    this.resetCurrentD();
    this.setCalendarBody();
  }
  @Emit()
  private resetCurrentD(): void {
    store.commit('searchdate/setCurrentD', new Date(this.valueYear, this.valueMonth - 1, 1));
  }
  get nowYear(): number {
    return this.nowD.getFullYear();
  }
  get nowMonth(): number {
    return this.nowD.getMonth() + 1;
  }
  get nowDate(): number {
    return this.nowD.getDate();
  }
  get currentYear(): number {
    return this.currentD.getFullYear();
  }
  get currentMonth(): number {
    return this.currentD.getMonth() + 1;
  }
  get nextD(): Date {
    const nextM = new Date(this.currentD.getFullYear(), this.currentD.getMonth(), 1);
    return new Date(nextM.setMonth(this.currentD.getMonth() + 1));
  }
  get nextYear(): number {
    return this.nextD.getFullYear();
  }
  get nextMonth(): number {
    return this.nextD.getMonth() + 1;
  }
  get inActiveCalPrev(): boolean {
    if (this.currentD <= this.nowD) {
      return false;
    } else {
      return true;
    }
  }
  get inActiveCalNext(): boolean {
    const maxD = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), 1);
    if (this.currentD < maxD) {
      return true;
    } else {
      return false;
    }
    return true;
  }
  @Emit()
  private inActiveCalCol(dt: Date|null): boolean {
    if (!dt || dt < this.nowD || dt > this.maxDate) {
      return false;
    } else {
      return true;
    }
  }
  @Emit()
  private setVal(dt: Date): void {
    if (this.inActiveCalCol(dt)) {
      store.commit('searchdate/setValueD', dt);
      this.closeCal();
    }
  }
  @Emit()
  private incrementCurrentCal() {
    if (this.inActiveCalNext) {
      store.commit('searchdate/setCurrentD', new Date(this.currentD.setMonth(this.currentD.getMonth() + 1)));
    }
  }
  @Emit()
  private decrementCurrentCal() {
    if (this.inActiveCalPrev) {
      store.commit('searchdate/setCurrentD', new Date(this.currentD.setMonth(this.currentD.getMonth() - 1)));
    }
  }
  @Watch('currentD')
  private onCurrentCalChange(newState: Date, oldState: Date) {
    this.setCalendarBody();
  }
  @Emit()
  private setCalendarBody(): void {
    this.currentCal = this.calendarBody(this.currentYear, this.currentMonth);
    this.nextCal = this.calendarBody(this.nextYear, this.nextMonth);
  }
  @Emit()
  private calendarBody(year: number, month: number): Date[][]|null[][] {
    const startDate = new Date(year, month - 1);
    const endDate  = new Date(year, month , 0);
    let startDay = startDate.getDay();
    startDay = startDay - 1;
    if (startDay < 0) {
      startDay = 6;
    }
    const endDay = endDate.getDate();
    const weeksArray: Date[][]|null[][] = [];
    for (let i = 1; i <= endDay; i++) {
      const row = Math.floor((i - 1 + startDay) / 7);
      const col = (i - 1 + startDay) % 7;
      if (!weeksArray[row]) {
        weeksArray[row] = [null, null, null, null, null, null, null];
      }
      weeksArray[row][col] = new Date(year, month - 1, i, 0);
    }
    return weeksArray;
  }
  @Emit()
  private calendarColText(date: Date|null): string {
    if (date) {
      return `${date.getDate()}`;
    } else {
      return '';
    }
  }
  get valueCal(): string {
    const zero = (num: number): string => {
      const ret = ( '00' + num ).slice( -2 );
      return `${ret}`;
    };
    return `${this.valueYear}-${zero(this.valueMonth)}-${zero(this.valueDate)}`;
  }
  @Watch('isActiveCal')
  private onActiveCalChange(newState: boolean, oldState: boolean) {
    const refs: any = this.$refs;
    if (newState) {
      $(refs.calendar).stop().slideDown();
    } else {
      $(refs.calendar).stop().slideUp();
    }
  }
  @Emit()
  private openCal() {
    this.resetCurrentD();
    this.isActiveCal = true;
  }
  @Emit()
  private closeCal() {
    this.isActiveCal = false;
  }
  @Emit()
  private async openReserveList() {
    const result: any = await store.dispatch('searchdate/getReserveList', {
      startDate: this.valD,
      area: this.$route.params.area,
    });
    store.commit('searchmodal/open', 'ModalSearchDate');
    // const tmp = magnificPopup;
    // $.magnificPopup.open({
    //   items: {
    //     src: '#reserveList',
    //     type: 'inline',
    //   },
    // });
  }
}
