import { Component, Vue } from 'vue-property-decorator';
import ViewPage from '@/mixins/Views';
import LayoutCol2 from '@/components/layout/LayoutCol2.vue';
@Component({
  components: {
    LayoutCol2,
  },
})
export default class ViewsHasSidenavi extends Vue {
}
