
import { Component, Mixins } from 'vue-property-decorator';
import SidenaviLine from './';
const Words: Dictionary = {
  label: 'LINEアプリで友だち追加する',
};
@Component
export default class SidenaviLineJa extends Mixins(SidenaviLine) {
  private words = Words;

  get isGroup(): boolean {
    return this.$store.getters['auth/group'];
  }
}
