
import { Component, Mixins } from 'vue-property-decorator';
import SidenaviRewardClub from '.';
const Words: Dictionary = {
  label: 'ポイントが貯まってさらにお得',
};
@Component
export default class SidenaviRewardClubJa extends Mixins(SidenaviRewardClub) {
  private words = Words;

  get isGroup(): boolean {
    return this.$store.getters['auth/group'];
  }
}
