
import { Component, Mixins } from 'vue-property-decorator';
import SidenaviSearcharea from './';
import NaviSerchnaviAreaLinks from '@/components/navi/NaviSerchnaviAreaLinks/NaviSerchnaviAreaLinks.vue';
const Words: Dictionary = {
  label: '宿泊エリアから探す',
};
@Component({
  components: {
    NaviSerchnaviAreaLinks,
  },
})
export default class SidenaviSearchareaJa extends Mixins(SidenaviSearcharea) {
  private words = Words;
}
