import { Component, Mixins } from "vue-property-decorator";
import ViewsHasSidenavi from "@/mixins/ViewsHasSidenavi";
import SidenaviSearchdate from "@/components/navi/sidenavi/SidenaviSearchdate/SidenaviSearchdate.vue";
import SidenaviSearchkeyword from "@/components/navi/sidenavi/SidenaviSearchkeyword/SidenaviSearchkeyword.vue";
import SidenaviSearcharea from "@/components/navi/sidenavi/SidenaviSearcharea/SidenaviSearcharea.vue";
import SidenaviSearchToday from "@/components/navi/sidenavi/SidenaviSearchToday/SidenaviSearchToday.vue";
import SidenaviLine from "@/components/navi/sidenavi/SidenaviLine/SidenaviLine.vue";
import SidenaviRewardClub from "@/components/navi/sidenavi/SidenaviRewardClub/SidenaviRewardClub.vue";
@Component({
  components: {
    SidenaviSearchdate,
    SidenaviSearchkeyword,
    SidenaviSearcharea,
    SidenaviSearchToday,
    SidenaviLine,
    SidenaviRewardClub,
  },
})
export default class ViewsHasSidenaviJa extends Mixins(ViewsHasSidenavi) {}
