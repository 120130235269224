
import { Component, Prop, Vue } from 'vue-property-decorator';
// @ is an alias to /src

@Component
export default class PageHeader extends Vue {
  private　name = 'pageheader';
  @Prop()
  private largeTitle!: string;
  @Prop()
  private smallTitle!: string;
}
